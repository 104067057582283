import React, { FC, useState } from 'react'
import styles from './Advert.module.scss'
import CloseIcon from '../../../../public/icons/close.svg'
import cn from 'classnames'

interface IAdvert {
  erid: string
  classNames?: {
    banner?: string
    button?: string
    close?: string
  }
}

const Advert: FC<IAdvert> = ({ erid, classNames }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className={cn(styles.button, classNames?.button)}
        aria-label="Открыть информацию о рекламодателе"
      >
        Реклама
      </button>
      <div
        className={cn(
          styles.banner,
          open && styles['banner--active'],
          classNames?.banner
        )}
      >
        <p className={styles.text}>
          <span className={cn(styles.textBlock, styles.bold)}>
            О рекламодателе
          </span>
          <span className={cn(styles.textBlock, styles.advert)}>
            Реклама ПАО Сбербанк
          </span>
          <span className={styles.textBlock}>erid: {erid}</span>
        </p>
        <button
          type="button"
          onClick={() => setOpen(false)}
          className={cn(styles.close, classNames?.close)}
          aria-label="Закрыть информацию о рекламодателе"
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  )
}

export default Advert
