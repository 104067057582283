import React, { FC } from 'react'
import styles from './Advert.module.scss'
import CloseIcon from '../../../../public/icons/close.svg'
import cn from 'classnames'

interface IBlockAdvert {
  erid: string
  classNames?: {
    banner?: string
    close?: string
  }
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
}

const BlockAdvert: FC<IBlockAdvert> = ({ erid, classNames, setOpen, open }) => {
  return (
    <div
      className={cn(
        styles.banner,
        open && styles['banner--active'],
        classNames?.banner
      )}
    >
      <p className={styles.text}>
        <span className={cn(styles.textBlock, styles.bold)}>
          О рекламодателе
        </span>
        <span className={cn(styles.textBlock, styles.advert)}>
          Реклама ПАО Сбербанк
        </span>
        <span className={styles.textBlock}>erid: {erid}</span>
      </p>
      <button
        type="button"
        onClick={() => setOpen(false)}
        className={cn(styles.close, classNames?.close)}
        aria-label="Закрыть информацию о рекламодателе"
      >
        <CloseIcon />
      </button>
    </div>
  )
}

export default BlockAdvert
