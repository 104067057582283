import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  DateTime: any;
};

export type File = {
  __typename?: 'File';
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  filesize?: Maybe<Scalars['BigInt']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type GigaArticle = {
  __typename?: 'GigaArticle';
  generatedText?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  publishedDate?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  sort: Scalars['Int'];
  sourceUrl?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title: Scalars['String'];
};

export type GigaArticleWhereInput = {
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

export type GigaArticlesWhereInput = {
  source?: InputMaybe<Scalars['String']>;
};

export type GigaKandinsky = {
  __typename?: 'GigaKandinsky';
  generatedImage?: Maybe<File>;
  id: Scalars['Int'];
  logo?: Maybe<File>;
  name: Scalars['String'];
  prompt?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  sort: Scalars['Int'];
  sourceUrl?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title: Scalars['String'];
};

export type GigaKandinskyWhereInput = {
  slug: Scalars['String'];
  source?: InputMaybe<Scalars['String']>;
};

export type GigaStoriesWhereInput = {
  source?: InputMaybe<Scalars['String']>;
};

export type GigaStory = {
  __typename?: 'GigaStory';
  audio?: Maybe<File>;
  generatedText?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  images?: Maybe<Array<File>>;
  name: Scalars['String'];
  publishedDate?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  sort: Scalars['Int'];
  sourceUrl?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title: Scalars['String'];
};

export type GigaStoryWhereInput = {
  slug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  flush: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  gigaArticle?: Maybe<GigaArticle>;
  gigaArticles?: Maybe<Array<GigaArticle>>;
  gigaKandinsky?: Maybe<GigaKandinsky>;
  gigaStories?: Maybe<Array<GigaStory>>;
  gigaStory?: Maybe<GigaStory>;
};


export type QueryGigaArticleArgs = {
  where: GigaArticleWhereInput;
};


export type QueryGigaArticlesArgs = {
  skip: Scalars['Int'];
  take: Scalars['Int'];
  where: GigaArticlesWhereInput;
};


export type QueryGigaKandinskyArgs = {
  where: GigaKandinskyWhereInput;
};


export type QueryGigaStoriesArgs = {
  skip: Scalars['Int'];
  take: Scalars['Int'];
  where: GigaStoriesWhereInput;
};


export type QueryGigaStoryArgs = {
  where: GigaStoryWhereInput;
};

export type GigaStoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GigaStoriesQuery = { __typename?: 'Query', gigaStories?: Array<{ __typename?: 'GigaStory', id: number, name: string, status: string, sourceUrl?: string | null, slug: string, sort: number, generatedText?: string | null, images?: Array<{ __typename?: 'File', id: string, url: string }> | null, audio?: { __typename?: 'File', id: string, url: string } | null }> | null };

export type GigaKandinskyQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GigaKandinskyQuery = { __typename?: 'Query', gigaKandinsky?: { __typename?: 'GigaKandinsky', id: number, name: string, status: string, slug: string, sort: number, prompt?: string | null, generatedImage?: { __typename?: 'File', id: string, url: string } | null, logo?: { __typename?: 'File', id: string, url: string } | null } | null };

export type GigaArticleSumQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GigaArticleSumQuery = { __typename?: 'Query', gigaArticle?: { __typename?: 'GigaArticle', id: number, name: string, status: string, sourceUrl?: string | null, publishedDate?: any | null, slug: string, sort: number, generatedText?: string | null } | null };


export const GigaStoriesDocument = gql`
    query GigaStories {
  gigaStories(where: {source: "ghost-prod-ru"}, take: 2, skip: 0) {
    id
    name
    status
    sourceUrl
    slug
    sort
    generatedText
    images {
      id
      url
    }
    audio {
      id
      url
    }
  }
}
    `;

/**
 * __useGigaStoriesQuery__
 *
 * To run a query within a React component, call `useGigaStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGigaStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGigaStoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGigaStoriesQuery(baseOptions?: Apollo.QueryHookOptions<GigaStoriesQuery, GigaStoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GigaStoriesQuery, GigaStoriesQueryVariables>(GigaStoriesDocument, options);
      }
export function useGigaStoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GigaStoriesQuery, GigaStoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GigaStoriesQuery, GigaStoriesQueryVariables>(GigaStoriesDocument, options);
        }
export type GigaStoriesQueryHookResult = ReturnType<typeof useGigaStoriesQuery>;
export type GigaStoriesLazyQueryHookResult = ReturnType<typeof useGigaStoriesLazyQuery>;
export type GigaStoriesQueryResult = Apollo.QueryResult<GigaStoriesQuery, GigaStoriesQueryVariables>;
export const GigaKandinskyDocument = gql`
    query GigaKandinsky($slug: String!) {
  gigaKandinsky(where: {source: "ghost-prod-ru", slug: $slug}) {
    id
    name
    status
    slug
    sort
    generatedImage {
      id
      url
    }
    prompt
    logo {
      id
      url
    }
  }
}
    `;

/**
 * __useGigaKandinskyQuery__
 *
 * To run a query within a React component, call `useGigaKandinskyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGigaKandinskyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGigaKandinskyQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGigaKandinskyQuery(baseOptions: Apollo.QueryHookOptions<GigaKandinskyQuery, GigaKandinskyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GigaKandinskyQuery, GigaKandinskyQueryVariables>(GigaKandinskyDocument, options);
      }
export function useGigaKandinskyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GigaKandinskyQuery, GigaKandinskyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GigaKandinskyQuery, GigaKandinskyQueryVariables>(GigaKandinskyDocument, options);
        }
export type GigaKandinskyQueryHookResult = ReturnType<typeof useGigaKandinskyQuery>;
export type GigaKandinskyLazyQueryHookResult = ReturnType<typeof useGigaKandinskyLazyQuery>;
export type GigaKandinskyQueryResult = Apollo.QueryResult<GigaKandinskyQuery, GigaKandinskyQueryVariables>;
export const GigaArticleSumDocument = gql`
    query GigaArticleSum($slug: String!) {
  gigaArticle(where: {source: "ghost-prod-ru", slug: $slug}) {
    id
    name
    status
    sourceUrl
    publishedDate
    slug
    sort
    generatedText
  }
}
    `;

/**
 * __useGigaArticleSumQuery__
 *
 * To run a query within a React component, call `useGigaArticleSumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGigaArticleSumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGigaArticleSumQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGigaArticleSumQuery(baseOptions: Apollo.QueryHookOptions<GigaArticleSumQuery, GigaArticleSumQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GigaArticleSumQuery, GigaArticleSumQueryVariables>(GigaArticleSumDocument, options);
      }
export function useGigaArticleSumLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GigaArticleSumQuery, GigaArticleSumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GigaArticleSumQuery, GigaArticleSumQueryVariables>(GigaArticleSumDocument, options);
        }
export type GigaArticleSumQueryHookResult = ReturnType<typeof useGigaArticleSumQuery>;
export type GigaArticleSumLazyQueryHookResult = ReturnType<typeof useGigaArticleSumLazyQuery>;
export type GigaArticleSumQueryResult = Apollo.QueryResult<GigaArticleSumQuery, GigaArticleSumQueryVariables>;