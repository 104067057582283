import React, { FC, useState } from 'react'
import { GIGA_CHAT_CLIENT } from '../../lib/constants'
import GigaChatIcon from '../../public/icons/giga-chat-text.svg'
import { useGigaStoriesQuery } from '../../queries/generated/giga-chat/schema'
import { splitText } from '../../utils/stories'
import Story from './Story'
import { SwiperProps, SwiperSlide, Swiper } from 'swiper/react'
import MaxWidth from '../layout/MaxWidth'
import ArrowIcon from '../../public/icons/arrow-down.svg'
import cn from 'classnames'
import Advert from '../common/GigaChat/Advert'
import CreateViaGigaChat from '../common/CreateViaGigaChat'
import styles from './Stories.module.scss'
import * as gigaChatStory from '../Stories/Story/moc'
import StoryPromo from './Story/FirstStory'

const Stories: FC<{ className?: string }> = ({ className }) => {
  const [activeEl, setActiveEl] = useState(null)
  const { data: gigaStories } = useGigaStoriesQuery({
    context: GIGA_CHAT_CLIENT,
  })

  const options: SwiperProps = {
    threshold: 2,
    longSwipesRatio: 0.9,
    mousewheel: {
      forceToAxis: true,
    },
    slidesPerView: 3,
    breakpoints: {
      850: {
        slidesPerView: 3,
      },
      560: {
        slidesPerView: 2,
      },
      350: { slidesPerView: 'auto' },
    },
  }

  return gigaStories.gigaStories?.length ? (
    <div className={cn(styles.container, className)}>
      <MaxWidth>
        <div className={cn('h3', styles.top)}>
          Важные новости коротко — от&nbsp;GigaChat Max&nbsp;
          <ArrowIcon />
        </div>
        <Swiper {...options} className={styles.slider}>
          <SwiperSlide
            className={styles.slide}
            key={`${gigaChatStory.default.id}-${0}`}
          >
            <StoryPromo
              images={gigaChatStory.default.images}
              promoContentImages={gigaChatStory.default.imagesContent}
              name={gigaChatStory.default.name}
              text={splitText({
                text: gigaChatStory.default.generatedText,
                isGigaChatPromo: true,
              })}
              audio={gigaStories.gigaStories[0].audio}
              onPlay={() => {
                activeEl === 0 ? setActiveEl(null) : setActiveEl(0)
              }}
              isActive={0 === activeEl}
              endHandler={() => setActiveEl(null)}
              isPromoSlide
              staticContentImage={gigaChatStory.default.imageContentStatic}
            />
          </SwiperSlide>
          {gigaStories.gigaStories
            .filter((story) => story?.images?.length)
            .map((story, index) => (
              <SwiperSlide
                className={styles.slide}
                key={`${story.id}-${index + 1}`}
              >
                <Story
                  images={story.images}
                  name={story.name}
                  text={splitText({
                    text: story.generatedText,
                  })}
                  audio={story.audio}
                  onPlay={() => {
                    activeEl === index + 1
                      ? setActiveEl(null)
                      : setActiveEl(index + 1)
                  }}
                  isActive={index + 1 === activeEl}
                  link={story.sourceUrl}
                  endHandler={() => setActiveEl(null)}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className={styles.advert}>
          <CreateViaGigaChat size="middle" />
          <Advert
            erid="2VtzqxMJbWP"
            classNames={{
              button: styles.advertButton,
              banner: styles.advertBanner,
            }}
          />
        </div>
      </MaxWidth>
    </div>
  ) : null
}

export default Stories
