import { Subtitles } from '../types'

function splitText({
  text,
  isGigaChatPromo,
}: {
  text: string
  isGigaChatPromo?: boolean
}): string[] {
  const sentences = isGigaChatPromo
    ? text.split('/')
    : text.split(/[.!?] (?=[A-ZА-Я])/g)

  return sentences.map((sentence) => sentence.trim())
}

function createElementsForTimeline<T>(
  step: number,
  array: T[]
): Subtitles<T>[] {
  const result: Subtitles<T>[] = []
  let startTime = 0

  for (let i = 0; i < array.length; i++) {
    result.push({
      endTime: startTime + step,
      startTime: startTime,
      element: array[i],
      index: i,
    })
    startTime += step
  }

  return result
}

export { createElementsForTimeline, splitText }
