export default {
  // todo: need id
  id: Date.now(),
  name: 'Как изменился Код Дурова вместе с GigaChat Max?',
  status: 'published',
  // todo: need sourceUrl
  // todo:need slug
  slug: '',
  // todo: need sort
  sort: Date.now(),
  generatedText:
    'GigaChat&nbsp;Max&nbsp;&mdash; нейросетевая модель от&nbsp;Сбера, теперь интегрирована в&nbsp;&laquo;Код Дурова&raquo; \n \n Читайте дальше, чтобы узнать, как именно GigaChat&nbsp;Max усилил привычный вам сайт!&nbsp;/ На&nbsp;главной появился интерактивный блок с&nbsp;горячей повесткой новостей, которые проанализированы и&nbsp;описаны в&nbsp;ёмком формате благодаря GigaChat&nbsp;MAX.&nbsp;/ Внутри длинных материалов появилась кнопка суммаризации от&nbsp;GigaChat&nbsp;Max. Она поможет быстро понять суть и&nbsp;ключевые моменты статьи.',

  images: [
    {
      id: '1-bg',
      url: '/images/specials/giga-chat/1-background.png',
    },
  ],
  imageContentStatic: {
    id: 'img-content-1',
    url: '/images/specials/giga-chat/image-1.png',
  },

  imagesContent: [
    {
      id: 'img-content-2',
      url: '/images/specials/giga-chat/image-2.png',
    },
    {
      id: 'img-content-3',
      url: '/images/specials/giga-chat/image-3-new.png',
    },
    {
      id: 'img-content-4',
      url: '/images/specials/giga-chat/image-4-new.png',
    },
  ],
}
