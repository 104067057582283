import React, { FC } from 'react'
import styles from './CreateViaGigaChat.module.scss'
import cn from 'classnames'
import { GIGA_CHAT_LINK } from '../../../lib/constants'
import ArrowGigaChatSvg from '../../../public/icons/arrow-without-stick.svg'

const CreateViaGigaChat: FC<{
  className?: string
  size?: 'middle' | 'big'
}> = ({ className, size = 'middle' }) => {
  return (
    <p className={cn(styles.note, styles[`note--${size}`], className)}>
      Реализовано через&nbsp;
      <a
        className={styles.link}
        onClick={(e) => e.stopPropagation()}
        href={GIGA_CHAT_LINK}
        target="_blank"
      >
        GigaChat Max&nbsp;
        <ArrowGigaChatSvg />
      </a>
    </p>
  )
}

export default CreateViaGigaChat
