import React, { FC, useEffect, useRef, useState } from 'react'
import PlayIcon from './play.svg'
import PauseIcon from './pause.svg'
import SoundOnIcon from './sound-on.svg'
import SoundOffIcon from './sound-off.svg'
import { createElementsForTimeline } from '../../../utils/stories'
import ArrowIcon from '../../../public/icons/arrow-without-stick.svg'
import { File, Subtitles } from '../../../types'
import cn from 'classnames'
import Image from '../../common/Image'
import styles from './Story.module.scss'
import gsap from 'gsap'
import Link from 'next/link'

interface IStory {
  images: File[]
  name: string
  text: string[]
  audio: File
  onPlay: () => void
  isActive: boolean
  endHandler: () => void
  link?: string
}

const Story: FC<IStory> = ({
  images,
  name,
  text,
  audio,
  onPlay,
  isActive,
  endHandler,
  link,
}) => {
  const audioRef = useRef(null)
  const imageBoxRef = useRef(null)
  const animation = useRef(null)
  const containerRef = useRef(null)
  const [isMuted, setIsMuted] = useState(false)
  const [progress, setProgress] = useState(0)
  const [currentSubtitle, setCurrentSubtitle] = useState('')
  const [subtitles, setSubtitles] = useState<Subtitles<string>[]>([])
  const [currentImg, setCurrentImg] = useState<Subtitles<File>>()
  const [imagesList, setImagesList] = useState<Subtitles<File>[]>([])
  const [isDirty, setIsDirty] = useState(false)
  const lastSubtitle = text[text.length - 1]
  let cleanedUrl = link?.split('kod.ru')[1]
  cleanedUrl = cleanedUrl?.startsWith('/') ? cleanedUrl?.slice(1) : cleanedUrl

  useEffect(() => {
    const audio = audioRef.current

    if (audio === null) return

    function handleTimeUpdate() {
      const currentTime = audioRef.current.currentTime
      const currentSub = subtitles.find(
        (subtitle) =>
          currentTime >= subtitle.startTime && currentTime <= subtitle.endTime
      )
      const currentImage = imagesList.find(
        (image) =>
          currentTime >= image.startTime && currentTime <= image.endTime
      )

      setCurrentSubtitle(currentSub ? currentSub.element : '')
      setCurrentImg(currentImage)
      setProgress((audio.currentTime / audio.duration) * 100)
    }

    function ended() {
      setIsDirty(false)
      endHandler()
    }

    audio.addEventListener('timeupdate', handleTimeUpdate)
    audio.addEventListener('ended', ended)

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate)
      audio.removeEventListener('ended', ended)
    }
  }, [audioRef.current, subtitles, imagesList])

  useEffect(() => {
    const audio = audioRef.current

    if (audio === null) return

    const step = parseInt((audio.duration / images.length).toFixed(0))

    setImagesList(createElementsForTimeline<File>(step, images))
  }, [audioRef.current, images])

  useEffect(() => {
    const audio = audioRef.current

    if (audio === null) return

    const step = parseInt((audio.duration / text.length).toFixed(0))

    setSubtitles(createElementsForTimeline<string>(step, text))
  }, [audioRef.current, text])

  useEffect(() => {
    isActive ? audioRef.current?.play() : audioRef.current?.pause()
  }, [isActive])

  useEffect(() => {
    const audio = audioRef.current
    const image = imageBoxRef.current

    if (!audio || !image) return

    animation.current = gsap.timeline({
      paused: true,
      repeat: -1,
    })

    animation.current.fromTo(
      image,
      { scale: 1 },
      {
        duration: audio.duration,
        ease: 'power2.inOut',
        scale: 1.2,
      }
    )

    const updateAnimation = () => {
      const currentTime = audio.currentTime
      animation.current.seek(
        (currentTime / audio.duration) * animation.current.duration()
      )
      requestAnimationFrame(updateAnimation)
    }

    audio.addEventListener('timeupdate', updateAnimation)

    return () => {
      audio.removeEventListener('timeupdate', updateAnimation)
    }
  }, [])

  const toggleMute = () => {
    const audio = audioRef.current
    audio.muted = !audio.muted

    setIsMuted(!isMuted)
  }

  const handleProgressChange = (e) => {
    const audio = audioRef.current
    const newProgress = e.target.value
    audio.currentTime = (newProgress / 100) * audio.duration

    setProgress(newProgress)
  }

  useEffect(() => {
    const subtitleElement = document.querySelector(`.${styles.subtitle} span`)
    const audio = audioRef.current

    if (!subtitleElement || !audio) return

    const fadeIn = gsap.fromTo(
      subtitleElement,
      { opacity: 0, y: 10 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        ease: 'power2.out',
      }
    )

    gsap.to(subtitleElement, {
      opacity: 0,
      y: -10,
      duration: 0.5,
      ease: 'power2.in',
      delay: audio.duration / text.length,
    })

    return () => {
      fadeIn.kill()
      gsap.killTweensOf(subtitleElement)
    }
  }, [currentSubtitle])

  return (
    <div className={styles.aspectRatioBox}>
      <div
        className={cn(styles.container, !isActive && styles['container--dark'])}
        onClick={() => {
          setIsDirty(true)
          onPlay()
        }}
        ref={containerRef}
      >
        <div className={styles.imageBox} ref={imageBoxRef}>
          {images.map((img, index) =>
            !isActive && isDirty ? (
              <Image
                key={`${images[0].url}-${index}`}
                src={images[0].url}
                alt={`${images[0].id}-изображение-${index}`}
                width={540}
                height={962}
                withOptimize
                className={cn(
                  styles.image,
                  (currentImg?.index
                    ? currentImg.index === index || currentImg.index > index
                    : index === 0) && styles['image--active']
                )}
                loader={({ src, width }) =>
                  `${
                    process.env.NEXT_PUBLIC_IMAGE_OPTIM
                  }/pr:sharp/rs:fill:${width}:${(width * 1.78).toFixed(
                    0
                  )}/q:90/plain/${encodeURIComponent(src)}`
                }
              />
            ) : (
              <Image
                key={`${img.url}-${index}`}
                src={img.url}
                alt={`${img.id}-изображение-${index}`}
                withOptimize
                width={540}
                height={962}
                className={cn(
                  styles.image,
                  (currentImg?.index
                    ? currentImg.index === index || currentImg.index > index
                    : index === 0) && styles['image--active']
                )}
                loader={({ src, width }) =>
                  `${
                    process.env.NEXT_PUBLIC_IMAGE_OPTIM
                  }/pr:sharp/rs:fill:${width}:${(width * 1.78).toFixed(
                    0
                  )}/q:90/plain/${encodeURIComponent(src)}`
                }
              />
            )
          )}
        </div>
        <div
          className={cn(styles.player, isActive && styles['player--active'])}
        >
          <audio ref={audioRef} src={audio?.url || '/audio/silence.mp3'} />
          {!isActive && (
            <div className={styles.playButton}>
              <PlayIcon />
            </div>
          )}
        </div>
        {isActive && (
          <div className={styles.pauseButton}>
            <PauseIcon />
          </div>
        )}
        {currentSubtitle && isActive && (
          <p
            className={cn(
              'gigaChatStoriesText',
              styles.subtitle,
              currentSubtitle.length > 160 && styles['subtitle--small-mobile'],
              !isActive && styles['subtitle--hidden']
            )}
          >
            <span>{currentSubtitle}</span>
            {currentSubtitle === lastSubtitle && (
              <Link className={styles.read} href={`/${cleanedUrl}`}>
                Читать полную версию материала
                <ArrowIcon className={styles.arrow} />
              </Link>
            )}
          </p>
        )}

        {isActive && audio?.url && (
          <button
            onClick={(e) => {
              e.stopPropagation()
              toggleMute()
            }}
            className={styles.soundButton}
            type="button"
          >
            {isMuted ? <SoundOffIcon /> : <SoundOnIcon />}
          </button>
        )}
        <div
          className={cn(
            styles.blur,
            isActive && styles['blur--bottomGeneratedStory']
          )}
        >
          <div
            className={cn(
              styles.titleBox,
              isActive && styles['titleBox--hidden']
            )}
          >
            <p className={styles.subtitlePreview}>
              GigaChat Max: коротко о главном
            </p>
            <p className={cn('gigaChatStoriesTitle', styles.title)}>{name}</p>
          </div>

          <Link
            href={`/${cleanedUrl}`}
            className={cn(styles.titleBox, styles['titleBox--link'])}
          >
            Полная версия&nbsp;
            <ArrowIcon />
          </Link>
        </div>

        <input
          type="range"
          min="0"
          max="100"
          value={progress}
          onChange={handleProgressChange}
          className={cn(styles.progress, isActive && styles['progress--show'])}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  )
}

export default Story
