import React, { FC, useEffect, useRef, useState } from 'react'
import PlayIcon from './play.svg'
import PauseIcon from './pause.svg'
import SoundOnIcon from './sound-on.svg'
import SoundOffIcon from './sound-off.svg'
import { createElementsForTimeline } from '../../../utils/stories'
import ArrowIcon from '../../../public/icons/arrow-without-stick.svg'
import { File, Subtitles } from '../../../types'
import cn from 'classnames'
import Image from '../../common/Image'
import styles from './Story.module.scss'
import gsap from 'gsap'
import Link from 'next/link'
import BlockAdvert from '../../common/GigaChat/Advert/BlockAdvert'

interface IStoryPromo {
  images: File[]
  name: string
  text: string[]
  audio: File
  onPlay: () => void
  isActive: boolean
  endHandler: () => void
  isPromoSlide: boolean
  link?: string
  promoContentImages: File[]
  staticContentImage: File
}

const StoryPromo: FC<IStoryPromo> = ({
  images,
  name,
  text,
  audio,
  onPlay,
  isActive,
  endHandler,
  isPromoSlide,
  link,
  promoContentImages,
  staticContentImage,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const imageBoxRef = useRef(null)
  const animation = useRef(null)
  const containerRef = useRef(null)
  const [isMuted, setIsMuted] = useState(false)
  const [progress, setProgress] = useState(0)
  const [currentSubtitle, setCurrentSubtitle] = useState('')
  const [subtitles, setSubtitles] = useState<Subtitles<string>[]>([])
  const [currentImg, setCurrentImg] = useState<Subtitles<File>>()
  const [currentImgContent, setCurrentImgContent] = useState<Subtitles<File>>()
  const [imagesList, setImagesList] = useState<Subtitles<File>[]>([])
  const [imagesListContent, setImagesListContent] = useState<Subtitles<File>[]>(
    []
  )
  const [isDirty, setIsDirty] = useState(false)
  const [isOpen, setOpen] = useState(false)
  let cleanedUrl = link?.split('kod.ru')[1]
  cleanedUrl = cleanedUrl?.startsWith('/') ? cleanedUrl?.slice(1) : cleanedUrl

  useEffect(() => {
    const audio = audioRef.current

    if (audio === null) return

    function handleTimeUpdate() {
      const currentTime = audioRef.current.currentTime

      //for first story only
      if (currentTime >= 15) {
        audioRef.current.pause()
        audioRef.current.currentTime = 0
        ended()
      }

      const currentSub = subtitles.find(
        (subtitle) =>
          currentTime >= subtitle.startTime && currentTime <= subtitle.endTime
      )
      const currentImage = imagesList.find(
        (image) =>
          currentTime >= image.startTime && currentTime <= image.endTime
      )

      const currentImgContent = imagesListContent.find(
        (image) =>
          currentTime >= image.startTime && currentTime <= image.endTime
      )

      setCurrentSubtitle(currentSub ? currentSub.element : '')
      setCurrentImg(currentImage)
      setCurrentImgContent(currentImgContent)
      setProgress((audio.currentTime / 15) * 100)
    }

    function ended() {
      setIsDirty(false)
      endHandler()
    }

    audio.addEventListener('timeupdate', handleTimeUpdate)
    audio.addEventListener('ended', ended)

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate)
      audio.removeEventListener('ended', ended)
    }
  }, [audioRef.current, subtitles, imagesList, imagesListContent])

  useEffect(() => {
    const audio = audioRef.current

    if (audio === null || !audio.duration) return

    const countedStep = parseInt((audio.duration / text.length).toFixed(0))
    const step = countedStep >= 5 ? 5 : countedStep

    setImagesList(createElementsForTimeline<File>(step, images))
    setImagesListContent(
      createElementsForTimeline<File>(step, promoContentImages)
    )
  }, [audioRef.current, images, promoContentImages])

  useEffect(() => {
    const audio = audioRef.current

    if (audio === null || !audio.duration) return

    const countedStep = parseInt((audio.duration / text.length).toFixed(0))
    const step = countedStep > 5 ? 5 : countedStep

    setSubtitles(createElementsForTimeline<string>(step, text))
  }, [audioRef.current, text])

  useEffect(() => {
    isActive ? audioRef.current?.play() : audioRef.current?.pause()
  }, [isActive])

  useEffect(() => {
    const audio = audioRef.current
    const image = imageBoxRef.current

    if (!audio || !audio.duration || !image) return

    animation.current = gsap.timeline({
      paused: true,
      repeat: -1,
    })

    animation.current.fromTo(
      image,
      { scale: 1 },
      {
        duration: audio.duration,
        ease: 'power2.inOut',
        scale: 1.2,
      }
    )

    const updateAnimation = () => {
      const currentTime = audio.currentTime
      animation.current.seek(
        (currentTime / audio.duration) * animation.current.duration()
      )
      requestAnimationFrame(updateAnimation)
    }

    audio.addEventListener('timeupdate', updateAnimation)

    return () => {
      audio.removeEventListener('timeupdate', updateAnimation)
    }
  }, [])

  const toggleMute = () => {
    const audio = audioRef.current
    audio.muted = !audio.muted

    setIsMuted(!isMuted)
  }

  const handleProgressChange = (e) => {
    const audio = audioRef.current
    const newProgress = e.target.value
    audio.currentTime = (newProgress / 100) * audio.duration

    setProgress(newProgress)
  }

  useEffect(() => {
    const subtitleElement = document.querySelector(`.${styles.subtitle} span`)
    const audio = audioRef.current

    if (!subtitleElement || !audio) return

    const fadeIn = gsap.fromTo(
      subtitleElement,
      { opacity: 0, y: 10 },
      {
        opacity: 1,
        y: 0,
        duration: 2,
        ease: 'power2.out',
      }
    )

    gsap.to(subtitleElement, {
      opacity: 0,
      y: -10,
      duration: 0.5,
      ease: 'power2.in',
      delay: audio.duration / text.length,
    })

    return () => {
      fadeIn.kill()
      gsap.killTweensOf(subtitleElement)
    }
  }, [currentSubtitle])

  return (
    <div className={styles.aspectRatioBox}>
      <BlockAdvert
        erid={'2VtzqxMJbWP'}
        classNames={{ close: styles.close, banner: styles.banner }}
        setOpen={setOpen}
        open={isOpen}
      />
      <div
        className={cn(
          styles.container,
          !isActive && styles['container--dark'],
          isPromoSlide && styles['container--promo']
        )}
        onClick={() => {
          setIsDirty(true)
          onPlay()
        }}
        ref={containerRef}
      >
        <div
          className={cn(styles.imageBox, isActive && styles['player--active'])}
          ref={imageBoxRef}
        >
          {images.map((img, index) =>
            !isActive ? (
              <Image
                key={`${images[0].url}-${index}`}
                src={img.url}
                alt={`${images[0].id}-изображение-${index}`}
                width={540}
                height={962}
                className={cn(
                  styles.image,
                  (currentImg?.index
                    ? currentImg.index === index || currentImg.index > index
                    : index === 0) && styles['image--active'],
                  (isActive || isDirty) &&
                    isPromoSlide &&
                    styles['image--promo']
                )}
              />
            ) : (
              <Image
                key={`${img.url}-${index}`}
                src={img.url}
                alt={`${img.id}-изображение-${index}`}
                width={540}
                height={962}
                className={cn(
                  styles.image,
                  (currentImg?.index
                    ? currentImg.index === index || currentImg.index > index
                    : index === 0) && styles['image--active'],
                  (isActive || isDirty) &&
                    isPromoSlide &&
                    styles['image--promo']
                )}
              />
            )
          )}
        </div>
        <div className={cn(styles.player)}>
          <audio ref={audioRef} src={'/audio/hero.mp3'} />
          {!isActive && (
            <div className={styles.playButton}>
              <PlayIcon />
            </div>
          )}

          <input
            type="range"
            min="0"
            max="100"
            value={progress}
            onChange={handleProgressChange}
            className={cn(
              styles.progress,
              isActive && styles['progress--show']
            )}
            onClick={(e) => e.stopPropagation()}
          />
        </div>

        <div className={styles.contentPromoImage}>
          {!isActive && (
            <Image
              key={`${staticContentImage.url}-0`}
              src={staticContentImage.url}
              alt={`${staticContentImage.id}-изображение-0`}
              width={540}
              height={962}
              className={cn(
                styles.image,
                styles['image--active'],
                isActive && styles['image--hidden']
              )}
            />
          )}
          {isActive &&
            promoContentImages.map((img, index) => {
              return (
                <Image
                  key={`${img.url}-${index}`}
                  src={img.url}
                  alt={`${img.id}-изображение-${index}`}
                  width={540}
                  height={962}
                  className={cn(
                    styles.image,
                    (currentImgContent?.index
                      ? currentImgContent.index === index
                      : index === 0) && styles['image--active']
                  )}
                />
              )
            })}
        </div>
        {isActive && (
          <div className={styles.pauseButton}>
            <PauseIcon />
          </div>
        )}
        {currentSubtitle && isActive && (
          <p
            className={cn(
              'gigaChatStoriesTextPromo',
              styles.subtitle,
              styles['subtitle--promo'],
              !isActive && styles['subtitle--hidden']
            )}
          >
            <span dangerouslySetInnerHTML={{ __html: currentSubtitle }} />
          </p>
        )}
        {isActive && audio?.url && (
          <button
            onClick={(e) => {
              e.stopPropagation()
              toggleMute()
            }}
            className={styles.soundButton}
            type="button"
          >
            {isMuted ? <SoundOffIcon /> : <SoundOnIcon />}
          </button>
        )}

        <div className={cn(styles.blur, isActive && styles['blur--bottom'])}>
          <div
            className={cn(
              'gigaChatStoriesTitle',
              styles.titleBox,
              isActive && styles['titleBox--hidden'],
              styles['titleBox--linkPromo']
            )}
          >
            <p className={styles.subtitlePreview}>
              GigaChat Max: коротко о главном
            </p>
            <p className={cn('gigaChatStoriesTitle', styles.title)}>{name}</p>
          </div>
          <div
            className={cn(
              styles.titleBox,
              styles['titleBox--link'],
              styles['titleBox--linkPromoBottom']
            )}
          >
            <Link href="/faq-gigachat-max-kandinsky">
              Узнай о всех возможностях в&nbsp;<b>FAQ-статье</b>&nbsp;
              <ArrowIcon />
            </Link>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setOpen(true)
              }}
            >
              Реклама
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoryPromo
